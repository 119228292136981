@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Fredoka One';
    src: url('./shared/assets/fonts/Fredoka/static/Fredoka-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka One';
    src: url('./shared/assets/fonts/Fredoka/static/Fredoka-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka One';
    src: url('./shared/assets/fonts/Fredoka/static/Fredoka-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka One';
    src: url('./shared/assets/fonts/Fredoka/static/Fredoka-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Fredoka One';
    src: url('./shared/assets/fonts/Fredoka/static/Fredoka-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Zwijsen Frutiger Bold';
    src: url('./shared/assets/fonts/ZwijsenFrutigerBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Zwijsen Frutiger Roman';
    src: url('./shared/assets/fonts/ZwijsenFrutigerRoman.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.font-zwijsen-frutiger {
    font-family: 'Zwijsen Frutiger Roman', serif;
}

.font-zwijsen-frutiger-bold {
    font-family: 'Zwijsen Frutiger Bold', serif;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes walk {
    0% {
        rotate: 0;
        scale: 1;
    }
    25% {
        rotate: 7deg;
        scale: 1.1;
    }
    50% {
        rotate: 0;
        scale: 1;
    }
    75% {
        rotate: -7.5deg;
        scale: 1.1;
    }
    100% {
        rotate: 0;
        scale: 1;
    }
}

.animate-walk {
    animation: walk 500ms ease-in-out forwards infinite;
}
